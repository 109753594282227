import api from '../api';
import Router from 'next/router';
import { getFirstImageMobileList, getImageMobileList } from '../util';
import { types, getParent, flow } from 'mobx-state-tree';
import { MerchantModel } from './MerchantStore.js';

const rentalMetaInitValue = {
  bedroom: null,
  bathroom: null,
  carPark: null,
  checkInDate: null,
  share_rent: '',
  rent_per_week: '',
  numOfTenant: '',
  genderPreference: '',
  livingDescription: [],
  rentType: null,
  roomType: null,
  roomNum: null,
  personNum: null,
  type: null, //租售类型
  type2: null, //商铺类型
  status: null,
  industry: null
};

const carMetaInitValue = {
  transmission: '',
  make: '',
  model: '',
  cc: '',
  fuel_type: '',
  price: 0,
  year: new Date().getFullYear() + '',
  kilometre: 0,
  minKilometre: 0,
  origin_country: '',
  import_country: '',
  nz_register_date: '',
  plate: '',
  features: [],
  private_value: null,
  dealer_value: null
};

const secondHandInitValue = {
  name: '',
  condition: '',
  price: '',
  type: '',
  deliverType: '',
  itemCategory: ''
};

const contactInitValue = {
  mobile: '',
  email: '',
  wechat: '',
  isMobileChecked: false,
  isEmailChecked: false,
  isWechatChecked: false,
  features: []
};

const JobInitValue = {
  jobType: [], //职位性质
  salary: null,
  visa: null,
  companyName: null,
  role: null, //职位类别
  position: null //职位名称
};

const eventMetaInitValue = {
  processing: false,
  startDate: null,
  endDate: null,
  price: 0,
  detailAddress: null,
  userInputField: [],
  successText: '',
  successImage: '',
  successAction: null,
  successBtnLabel: null
};

const businessMetaInitValue = {
  type: null,
  price: 0,
  listingId: null
};

const eventMetaModel = types.model('eventMetaModel', {
  processing: types.maybeNull(types.boolean),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  price: types.maybeNull(types.union(types.number, types.string)),
  detailAddress: types.maybeNull(types.string),
  userInputField: types.maybeNull(types.array(
    types.model({
      type: types.string,
      placeholder: types.string,
      required: types.boolean,
      index: types.number
    }))),
  successText: types.maybeNull(types.string),
  successImage: types.maybeNull(types.string),
  successAction: types.maybeNull(types.string),
  successBtnLabel: types.maybeNull(types.string)
});

const businessMetaModel = types.model('businessMetaModel', {
  price: types.maybeNull(types.union(types.number, types.string)),
  type: types.maybeNull(types.string),
  listingId: types.maybeNull(types.string)
});

const contactMetaModel = types.model('contactMetaModel', {
  mobile: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  wechat: types.maybeNull(types.string),
  isMobileChecked: types.maybeNull(types.boolean),
  isEmailChecked: types.maybeNull(types.boolean),
  isWechatChecked: types.maybeNull(types.boolean),
  features: types.maybeNull(types.array(types.string))
});

const rentalMetaModel = types.model('rentelMetaModel', {
  bedroom: types.maybeNull(types.number),
  bathroom: types.maybeNull(types.number),
  carPark: types.maybeNull(types.number),
  checkInDate: types.maybeNull(types.union(types.Date, types.string)),
  share_rent: types.maybeNull(types.string),
  rent_per_week: types.maybeNull(types.union(types.number, types.string)),
  numOfTenant: types.maybeNull(types.union(types.number, types.string)),
  genderPreference: types.maybeNull(types.string),
  livingDescription: types.maybeNull(types.array(types.string)),
  rentType: types.maybeNull(types.string),
  roomType: types.maybeNull(types.string),
  roomNum: types.maybeNull(types.union(types.number, types.string)),
  personNum: types.maybeNull(types.number),
  type: types.maybeNull(types.string), //租售类型
  type2: types.maybeNull(types.string), //商铺类型
  status: types.maybeNull(types.string),
  industry: types.maybeNull(types.string)
});

const carMetaModel = types.model('carMetaModel', {
  price: types.maybeNull(types.union(types.number, types.string)),
  cc: types.maybeNull(types.union(types.number, types.string)),
  dealer_value: types.maybeNull(
    types.union(
      types.model({
        low: types.number,
        high: types.number,
        average: types.number
      }),
      types.number,
      types.string
    )
  ),
  features: types.maybeNull(types.array(types.string)),
  fuel_type: types.maybeNull(types.string),
  import_country: types.maybeNull(types.string),
  kilometre: types.maybeNull(types.union(types.number, types.string)),
  make: types.maybeNull(types.string),
  minKilometre: types.maybeNull(types.union(types.number, types.string)),
  mobile: types.maybeNull(types.string),
  model: types.maybeNull(types.string),
  nz_register_date: types.maybeNull(types.string),
  origin_country: types.maybeNull(types.string),
  plate: types.maybeNull(types.string),
  private_value: types.maybeNull(
    types.union(
      types.model({
        low: types.number,
        high: types.number,
        average: types.number
      }),
      types.number,
      types.string
    )
  ),
  transmission: types.maybeNull(types.string),
  wechat: types.maybeNull(types.string),
  year: types.maybeNull(types.union(types.number, types.string))
});

const ershouMetaModel = types.model('ershouMetaModel', {
  name: types.maybeNull(types.string),
  condition: types.maybeNull(types.string),
  price: types.maybeNull(types.union(types.string, types.number)),
  type: types.maybeNull(types.union(types.string, types.number)),
  deliverType: types.maybeNull(types.string),
  itemCategory: types.maybeNull(types.string)
});

const jobMetaModel = types.model('jobMetaModel', {
  jobType: types.maybeNull(types.array(types.string)), //职位性质
  salary: types.maybeNull(types.string),
  visa: types.maybeNull(types.string),
  companyName: types.maybeNull(types.string),
  role: types.maybeNull(types.string), //职位类别
  position: types.maybeNull(types.string) //职位名称
});

const fileListModel = types.model('fileListModel', {
  uid: types.union(types.string, types.number),
  status: types.string,
  url: types.maybeNull(types.string),
  response: types.maybeNull(types.string)
});

export const TopicModel = types.model('topicModel', {
  id: types.maybeNull(types.number),
  city_id: types.maybeNull(types.number),
  area_id: types.maybeNull(types.number),
  subArea_id: types.maybeNull(types.number),
  merchant_id: types.maybeNull(types.number),
  category_name: types.maybeNull(types.string),
  sub_category_name: types.maybeNull(types.string),
  third_category_name: types.maybeNull(types.string),
  status: types.maybeNull(types.number),
  content: types.maybeNull(types.string),
  fileList: types.maybeNull(types.array(types.frozen())),
  fileList2: types.maybeNull(types.array(types.frozen())),
  city: types.maybeNull(
    types.model('CityModel', {
      id: types.number,
      name: types.maybeNull(types.string),
      chinese_name: types.maybeNull(types.string),
      order: types.maybeNull(types.number),
      latitude: types.maybeNull(types.string),
      longitude: types.maybeNull(types.string)
    })
  ),
  area: types.maybeNull(
    types.model('AreaModel', {
      chinese_name: types.maybeNull(types.string),
      city_id: types.number,
      id: types.number,
      name: types.string,
      order: types.maybeNull(types.number),
      parent_id: types.maybeNull(types.number)
    })
  ),
  sub_area: types.maybeNull(
    types.model('SubAreaModel', {
      chinese_name: types.maybeNull(types.string),
      city_id: types.number,
      id: types.number,
      name: types.string,
      order: types.maybeNull(types.number),
      parent_id: types.maybeNull(types.number)
    })
  ),
  title: types.maybeNull(types.string),
  view_count: types.maybeNull(types.frozen()),
  contact: types.maybeNull(types.string),
  images: types.array(types.string),
  meta: types.frozen(),
  merchant: types.maybeNull(MerchantModel),
  merchants: types.maybeNull(types.array(MerchantModel)),
  watched: types.maybeNull(types.number),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  renewed_at: types.maybeNull(types.string),
  published_at: types.maybeNull(types.string),
  is_watched: types.maybeNull(types.boolean),
  is_deleted: types.maybeNull(types.boolean),
  canRenew: types.maybeNull(types.boolean),
  auto_renew: types.maybeNull(types.boolean),
  auto_renew_expire_at: types.maybeNull(types.string),
  user: types.frozen()
});

export const TOPIC_CATEGORY = {
  CAR: 'cheliang',
  FANG: 'fang',
  JOB: 'gongzuo',
  USED: 'ershou',
  ACTIVITY: 'huodong',
  TRIP: 'lvyou',
  SERVICE: 'fuwu',
  BUSINESS: 'shengyi',
  TUANTUAN: 'tuantuan',
  SOCIAL: 'shequ'
};

export const TopicStoreInitialValues = {
  city_id: 0,
  area_id: 0,
  subArea_id: 0,
  merchant_id: 0,
  images: [],
  content: '',
  fileList: [],
  category_name: '',
  category_level_2: '',
  title: '',
  rentalMeta: rentalMetaInitValue,
  carMeta: carMetaInitValue,
  secondHandMeta: secondHandInitValue,
  contactMeta: contactInitValue,
  jobMeta: JobInitValue,
  topicData: {},
  dataChecked: true,
  edited: false,
  ad: null
};

const TopicStore = types
  .model('TopicStore', {
    city_id: types.maybeNull(types.number),
    area_id: types.maybeNull(types.number),
    subArea_id: types.maybeNull(types.number),
    merchant_id: types.maybeNull(types.number),
    images: types.maybeNull(types.array(types.string)),
    content: types.maybeNull(types.string),
    fileList: types.optional(types.array(fileListModel), []),
    fileList2: types.optional(types.array(fileListModel), []),
    category_name: types.maybeNull(types.string),
    category_level_2: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    rentalMeta: types.optional(rentalMetaModel, rentalMetaInitValue),
    carMeta: types.optional(carMetaModel, carMetaInitValue),
    secondHandMeta: types.optional(ershouMetaModel, secondHandInitValue),
    contactMeta: types.optional(contactMetaModel, contactInitValue),
    jobMeta: types.optional(jobMetaModel, JobInitValue),
    eventMeta: types.optional(eventMetaModel, eventMetaInitValue),
    businessMeta: types.optional(businessMetaModel, businessMetaInitValue),
    topicData: types.maybeNull(TopicModel),
    dataChecked: types.maybeNull(types.boolean),
    edited: types.maybeNull(types.boolean),
    ad: types.frozen()
  })
  .actions(self => ({
    setLevel2(level2) {
      self.category_level_2 = level2;
    },
    setEdited() {
      self.edited = true;
    },
    setFileList(fileList) {
      self.fileList = [...fileList];
    },
    setFileList2(fileList) {
      self.fileList2 = [...fileList];
    },
    setMerchantId(id) {
      self.merchant_id = id;
    },
    setCheckResult(result) {
      self.dataChecked = !!result;
    },
    clear() {
      self.city_id = null;
      self.area_id = null;
      self.subArea_id = null;
      self.merchant_id = 0;
      self.images = [];
      self.content = '';
      self.fileList = [];
      self.fileList2 = [];
      self.category_name = '';
      self.category_level_2 = '';
      self.title = '';
      self.rentalMeta = rentalMetaInitValue;
      self.carMeta = carMetaInitValue;
      self.topicData = undefined;
      self.businessMeta = businessMetaInitValue;
      self.eventMeta = eventMetaInitValue;
      self.secondHandMeta = secondHandInitValue;
      self.contactMeta = contactInitValue;
      self.jobMeta = JobInitValue;
      self.dataChecked = true;
      self.edited = false;
      getParent(self).topicNewCarUIStore.clear();
    },
    initCarMeta() {
      self.carMeta = carMetaInitValue;
    },
    setCarType() {
      self.setCategory('cheliang');
    },
    setHouseType() {
      self.setCategory('fang');
    },
    setCity(id) {
      self.city_id = id;
      self.area_id = null;
      self.subArea_id = null;
    },
    setArea(areaId) {
      self.area_id = areaId;
      self.subArea_id = null;
    },
    setSubArea(id) {
      self.subArea_id = id;
    },
    setCategory(category) {
      self.category_name = category;
    },
    setTitle(title) {
      self.title = title;
    },
    setContent(content) {
      self.content = content;
    },
    addNewImage(image) {
      self.images.add(image);
    },
    removeImage(image) {
      self.images.delete(image);
    },
    setImages(images) {
      self.images = [...images];
    },
    setRentalMeta(object) {
      Object.assign(self.rentalMeta, object);
    },
    setCarMeta(object) {
      Object.assign(self.carMeta, object);
    },
    setSecondHandMeta(object) {
      Object.assign(self.secondHandMeta, object);
    },
    setContactMeta(object) {
      Object.assign(self.contactMeta, object);
    },
    setJobMeta(object) {
      Object.assign(self.jobMeta, object);
    },
    setBusinessMeta(object) {
      Object.assign(self.businessMeta, object);
    },
    setEventMeta(object) {
      Object.assign(self.eventMeta, object);
    },
    getTopicById: flow(function* getTopicById(id) {
      const { data } = yield api.topic.id(id);
      if (!data || data.code) {
        if (typeof window !== 'undefined') {
          Router.replace('/topicNotFound');
        }
        return false;
      }
      data.topic.fileList = data.topic.images.map((image, i) => ({
        uid: i,
        status: 'done',
        url:
          i === 0 ? getFirstImageMobileList(image) : getImageMobileList(image),
        response: image
      }));
      self.initWithTopic(data.topic);
      self.ad = data.ad;

      return data.topic;
    }),
    initWithTopic(topic) {
      self.title = topic.title;
      self.city_id = topic.city ? topic.city.id : null;
      self.area_id = topic.area ? topic.area.id : null;
      self.subArea_id = topic.sub_area ? topic.sub_area.id : null;
      self.images = [...topic.images];
      self.merchant_id = topic.merchant ? topic.merchant.id : 0;
      self.content = topic.content;
      self.dataChecked = true;
      self.category_name = topic.category_name;
      self.category_level_2 = topic.sub_category_name;
      self.fileList =
        topic.fileList && topic.fileList.length > 0 ? [...topic.fileList] : [];
      self.contactMeta = {
        mobile: topic.meta.mobile,
        email: topic.meta.email,
        wechat: topic.meta.wechat,
        isMobileChecked: topic.meta.isMobileChecked,
        isEmailChecked: topic.meta.isEmailChecked,
        isWechatChecked: topic.meta.isWechatChecked,
        features: []
      };
      self.topicData = JSON.parse(JSON.stringify(topic));
      switch (topic.category_name) {
        case TOPIC_CATEGORY.CAR: {
          self.setCarMeta(topic.meta);
          break;
        }
        case TOPIC_CATEGORY.FANG: {
          const meta = self._patchFangMeta(topic.meta);
          self.setRentalMeta(meta);
          break;
        }
        case TOPIC_CATEGORY.USED: {
          if (topic.third_category_name) {
            topic.itemCategory = topic.third_category_name;
          }
          const meta = self._patchUsedMeta(topic.meta);
          self.setSecondHandMeta(meta);
          break;
        }
        case TOPIC_CATEGORY.JOB: {
          const meta = self._patchJobMeta(topic.meta);
          self.setJobMeta(meta);
          break;
        }
        case TOPIC_CATEGORY.ACTIVITY: {
          self.setEventMeta(topic.meta);
          if (topic.meta.successImage){
            self.fileList2 = [{
              uid: 1,
              status: 'done',
              url: getFirstImageMobileList(topic.meta.successImage),
              response: topic.meta.successImage
            }]
          }
          break;
        }
        case TOPIC_CATEGORY.BUSINESS: {
          self.setBusinessMeta(topic.meta);
          break;
        }
        default: {
        }
      }
    },
    _patchFangMeta(meta) {
      const newMeta = Object.assign({}, meta);
      if (meta.livingDescription) {
        const desc = meta.livingDescription.map(item => {
          switch (item) {
            case 'bathroom': {
              return '独立卫浴';
            }
            case 'entrance': {
              return '独立出入';
            }
            case 'pet': {
              return '可养宠物';
            }
            case 'parking': {
              return '独立车位';
            }
            case 'water': {
              return '包含水费';
            }
            case 'electricity': {
              return '包含电费';
            }
            case 'broadband': {
              return '包含宽带';
            }
            case 'furniture': {
              return '基本家具';
            }
            default:
              return item;
          }
        });
        newMeta.livingDescription = desc;
      }

      if (meta.genderPreference === 'no matter') {
        newMeta.genderPreference = 'noMatter';
      }

      if (meta.type2) {
        switch (meta.type2) {
          case 'liudongtanwei': {
            newMeta.type2 = '流动摊位';
            break;
          }
          case 'xiezilou': {
            newMeta.type2 = '写字楼';
            break;
          }
          case 'dianpu': {
            newMeta.type2 = '店铺';
            break;
          }
          case 'guitai': {
            newMeta.type2 = '柜台';
          }
        }
      }

      if (meta.status) {
        switch (meta.status) {
          case 'jingyingzhong': {
            newMeta.status = '经营中';
            break;
          }
          case 'kongzhizhong': {
            newMeta.status = '空置中';
            break;
          }
        }
      }

      if (meta.type) {
        switch (meta.type) {
          case 'chuzu': {
            newMeta.type = '出租';
            break;
          }
          case 'chushou': {
            newMeta.type = '出售';
            break;
          }
        }
      }
      return newMeta;
    },
    _patchJobMeta(meta) {
      const newMeta = Object.assign({}, meta);
      if (meta.role) {
        switch (meta.role) {
          case 'xingzheng': {
            newMeta.role = '行政/文职/管理';
            break;
          }
          case 'xiaoshou': {
            newMeta.role = '销售/客服/运营';
            break;
          }
          case 'shichang': {
            newMeta.role = '市场/编辑/设计';
            break;
          }
          case 'jinrong': {
            newMeta.role = '金融/法律/教育';
            break;
          }
          case 'yiliao': {
            newMeta.role = '医疗/美容/休闲';
            break;
          }
          case 'laogong': {
            newMeta.role = '劳工/物流/汽修';
            break;
          }
          case 'canyin': {
            newMeta.role = '餐饮/家政/服务';
            break;
          }
          case 'wangluo': {
            newMeta.role = '网络/IT/通信';
            break;
          }
          case 'jianzhu': {
            newMeta.role = '建筑/水电/维修';
            break;
          }
        }
      }
      if (meta.salary) {
        switch (meta.salary) {
          case 'lowest': {
            newMeta.salary = '最低工资';
            break;
          }
          case 'average': {
            newMeta.salary = '高于最低工资';
            break;
          }
          case 'custom': {
            newMeta.salary = '面议';
            break;
          }
        }
      }
      if (meta.jobType) {
        newMeta.jobType = meta.jobType.map(item => {
          switch (item) {
            case 'fulltime': {
              item = '全职';
              break;
            }
            case 'parttime': {
              item = '兼职';
              break;
            }
            case 'intern': {
              item = '实习生';
              break;
            }
            case 'volunteer': {
              item = '志愿者';
              break;
            }
          }
          return item;
        });
      }
      return newMeta;
    },
    _patchUsedMeta(meta) {
      const newMeta = Object.assign({}, meta);
      if (meta.itemCategory) {
        switch (meta.itemCategory) {
          case 'shoujizhoubian': {
            newMeta.itemCategory = '手机周边';
            break;
          }
          case 'jiajujiadian': {
            newMeta.itemCategory = '家居家电';
            break;
          }
          case 'diannaoshuma': {
            newMeta.itemCategory = '电脑数码';
            break;
          }
          case 'yibaoshipin': {
            newMeta.itemCategory = '衣包饰品';
            break;
          }
          case 'meironghufu': {
            newMeta.itemCategory = '美容护肤';
            break;
          }
          case 'youyiyingshi': {
            newMeta.itemCategory = '游戏影视';
            break;
          }
          case 'xuexibangong': {
            newMeta.itemCategory = '学习办公';
            break;
          }
          case 'MUYINGYONGPIN': {
            newMeta.itemCategory = '母婴用品';
            break;
          }
          case 'baobeimengchong': {
            newMeta.itemCategory = '宝贝萌宠';
            break;
          }
          case 'guoshulvzhi': {
            newMeta.itemCategory = '果蔬绿植';
            break;
          }
          case 'huwaiyundong': {
            newMeta.itemCategory = '户外运动';
            break;
          }
          case 'qitawupin': {
            newMeta.itemCategory = '其他物品';
            break;
          }
        }
      }
      return meta;
    },
    createNewTopic: flow(function* () {
      const params = self.getParams();
      const { data } = yield api.topic.create(params);
      if (data && !data.code) {
        self.topicData = data;
        getParent(self).userStore.addedNewPost(data);
        return true;
      } else {
        return false;
      }
    }),
    async updateNewTopic(params) {
      if (!self.topicData) {
        return false;
      }
      if (!params) {
        const currentParam = self.getParams();
        return await self.updateTopic(self.topicData.id, currentParam);
      } else {
        return await self.updateTopic(self.topicData.id, params);
      }
    },

    updateTopic: flow(function* (id, params) {
      if (!id) return false;
      if (params && params.images) {
        params.images = Array.from(params.images);
      }
      const { data } = yield api.topic.update(
        id,
        params ? params : self.getTopicParams()
      );
      if (data && !data.code) {
        self.topicData = data;
        getParent(self).userStore.UpdatePost(id, data);
        return true;
      } else {
        return false;
      }
    }),
    async valuationByModel() {
      const { data } = await api.car.valuationByModel({
        km: self.carMeta.kilometre,
        year: self.carMeta.year,
        make: self.carMeta.make,
        model: self.carMeta.model
      });
      if (data && !data.code) {
        self.setCarMeta({
          price: data.private_value.average,
          private_value: data.private_value,
          dealer_value: data.dealer_value
        });
      }
    },
    getTopicParams() {
      return self.getParams();
    },

    getParams() {
      let params = {
        city_id: self.city_id,
        area_id: self.area_id,
        merchant_id: self.merchant_id,
        sub_area_id: self.subArea_id,
        title: self.title,
        meta: {},
        category_name: self.category_name,
        sub_category_name: self.category_level_2,
        content: self.content
      };
      if (self.images) {
        params.images = Array.from(self.images);
      }
      switch (self.category_name) {
        case TOPIC_CATEGORY.CAR: {
          params.meta = Object.assign(params.meta, self.carMeta);
          break;
        }
        case TOPIC_CATEGORY.FANG: {
          params.meta = Object.assign(params.meta, self.rentalMeta);
          if (params.meta.rent_per_week) {
            params.meta.rent_per_week = parseInt(params.meta.rent_per_week, 10);
          }
          break;
        }
        case TOPIC_CATEGORY.JOB: {
          params.meta = Object.assign(params.meta, self.jobMeta);
          break;
        }
        case TOPIC_CATEGORY.USED: {
          params.meta = Object.assign(params.meta, self.secondHandMeta);
          if (params.meta.price) {
            params.meta.price = parseInt(params.meta.price, 10);
          }
          break;
        }
        case TOPIC_CATEGORY.ACTIVITY: {
          params.meta = Object.assign(params.meta, self.eventMeta);
        }
        case TOPIC_CATEGORY.BUSINESS: {
          params.meta = Object.assign(params.meta, self.businessMeta);
          if (params.meta.price) {
            params.meta.price = parseInt(params.meta.price, 10);
          }
          break;
        }
      }
      Object.assign(params.meta, self.contactMeta);
      return params;
    }
  }));

export default TopicStore;
